
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const getLanguage = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("lang") === "1" ? "cs" : "en";
};

i18n
    .use(initReactI18next)
    .init({
        lng: getLanguage(),
        fallbackLng: "cs",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            cs: {
                translation: {
                    documentTitle: "Potvrzení účasti",
                    loading: "Načítám",
                    tooLateMessage: "Omlouváme se, ale již není možné potvrdit účast na této události.",
                    startDate: "Začátek",
                    endDate: "Konec",
                    place: "Místo",
                    organizer: "Organizátor",
                    reaction: "Moje reakce",
                    participantsPanel: "Potvzení účastníci",
                    attendInPersonPanel: "Zúčastní se",
                    attendRemotelyPanel: "Zúčastní se vzdáleně",
                    questionSeparator: "Otázky",
                    submitButton: "Odeslat",
                    closeButton: "Zavřít",
                    selectReaction: "Vyberte zda se zúčastníte",
                    showParticipantsButton: "Zobrazit účastníky",
                    attendInPerson: "Zúčastním se",
                    attendRemotely: "Zúčastním se vzdáleně",
                    iAmInterested: "Zajímá mě to",
                    wontAttend: "Nezúčastním se",
                    dataSent: "Data úspěšně odeslána. Nyní můžete formulář zavřít.",
                }
            },
            en: {
                translation: {
                    documentTitle: "Confirm attendance",
                    loading: "Loading",
                    tooLateMessage: "We are sorry, but it is not possible to confirm attendance to this event anymore.",
                    startDate: "Start date",
                    endDate: "End date",
                    place: "Location",
                    organizer: "Organizer",
                    reaction: "My reaction",
                    participantsPanel: "Confirmed participants",
                    attendInPersonPanel: "Will attend",
                    attendRemotelyPanel: "Will attend remotely",
                    questionSeparator: "Questions",
                    submitButton: "Submit",
                    closeButton: "Close",
                    selectReaction: "Choose your attendance",
                    showParticipantsButton: "Show participants",
                    attendInPerson: "I will attend",
                    attendRemotely: "I will attend remotely",
                    iAmInterested: "I am interested in it",
                    wontAttend: "I will not attend",
                    dataSent: "Data successfully submitted. You can close this form now.",
                }
            },
        }
    });

export default i18n;
