import { useTranslation } from "react-i18next";
import { Stack, Panel, PrimaryButton } from "@fluentui/react";
import { IParticipant } from "../interfaces";

export interface IQuestionListProps {
    participants: IParticipant[];
    onClose: () => void;
}

const ParticipantList = ({ participants, onClose }: IQuestionListProps) => {
    const { t } = useTranslation();

    const onRenderFooterContent = () => (
        <PrimaryButton onClick={onClose}>
            {t("closeButton")}
        </PrimaryButton>
    );

    return (
        <Panel
            className="panel"
            headerText={t("participantsPanel") ?? ""}
            isOpen
            isLightDismiss
            isFooterAtBottom
            onDismiss={onClose}
            onRenderFooterContent={onRenderFooterContent}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack.Item>
                        <strong>{t("attendInPersonPanel")}</strong>
                    </Stack.Item>
                    {participants.filter((x: IParticipant) => x.reaction === 1).map((x: IParticipant) =>
                        <Stack.Item key={x.userName}>{x.userName}</Stack.Item>
                    )}
                </Stack>


                <Stack tokens={{ childrenGap: 5 }}>
                    <Stack.Item>
                        <strong>{t("attendRemotelyPanel")}</strong>
                    </Stack.Item>
                    {participants.filter((x: IParticipant) => x.reaction === 2).map((x: IParticipant) =>
                        <Stack.Item key={x.userName}>{x.userName}</Stack.Item>
                    )}
                </Stack>
            </Stack>
        </Panel>
    )
}

export default ParticipantList;
