import { IAvailableAnswer, IData, IParticipant, IQuestion, IRequest } from "../interfaces";
import { getLanguage } from "./i18n";

export const getEvent = async (participantId: string): Promise<IData> => {
    const cs = getLanguage() === "cs";
    const url = `/api/event?participantid=${participantId}`;
    const response = await fetch(url);
    const json = await response.json();
    const event = json.event;
    return {
        event: {
            id: event.kpcs_eventid,
            name: cs ? event.kpcs_name : event.kpcs_name_en,
            description: cs ? event.kpcs_description : event.kpcs_description_en,
            eventDate: event.kpcs_eventdate,
            eventEndDate: event.kpcs_eventenddate,
            latestResponseDate: event.kpcs_latestresponsedate,
            place: event.kpcs_place,
            organizer: event.kpcs_organizer,
            teamsEvent: !!event.kpcs_teamsurl,
            participants: event.kpcs_ParticipantEventKpcsEvent.map((x: any) => ({
                reaction: x.kpcs_reaction,
                userName: x["_kpcs_contact_value@OData.Community.Display.V1.FormattedValue"]
            } as IParticipant))
        },
        questions: json.questions.map((x: any) => ({
            id: x.kpcs_questionid,
            answerType: x.kpcs_answertype,
            sortOrder: x.kpcs_sortorder,
            value: cs ? x.kpcs_name : x.kpcs_name_en,
            valueInternal: x.kpcs_name,
            availableAnswers: x.kpcs_kpcs_availableanswer_Question_kpcs_questi.map((a: any) => ({
                id: a.kpcs_availableanswerid,
                value: cs ? a.kpcs_name : a.kpcs_name_en,
                valueInternal: a.kpcs_name
            } as IAvailableAnswer))
        } as IQuestion))
    } as IData;
};

export const sendResponse = async (data: IRequest) => {
    await fetch("/api/event", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
};
