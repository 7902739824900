import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import { IEvent } from "../interfaces";
import { convertToDate } from "../helpers/dateHelpers";

const EventInfo = ({ description, eventDate, eventEndDate, place, organizer }: IEvent) => {
    const { t } = useTranslation();

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item style={{ textAlign: "justify" }}>
                <span dangerouslySetInnerHTML={{ __html: description ?? "" }}></span>
            </Stack.Item>
            
            <Stack horizontal horizontalAlign="stretch" tokens={{ childrenGap: 10 }} grow>
                <Stack.Item className="half">
                    {t("startDate")}<br />{convertToDate(eventDate).toLocaleString()}
                </Stack.Item>
                <Stack.Item className="half">
                    {t("endDate")}<br />{convertToDate(eventEndDate).toLocaleString()}
                </Stack.Item>
            </Stack>

            <Stack horizontal horizontalAlign="stretch" tokens={{ childrenGap: 10 }} grow>
                {place &&
                    <Stack.Item className="half">
                        {t("place")}<br />{place}
                    </Stack.Item>
                }
                {organizer &&
                    <Stack.Item className="half">
                        {t("organizer")}<br />{organizer}
                    </Stack.Item>
                }
            </Stack>
        </Stack>
    )
}

export default EventInfo;
