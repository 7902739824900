import { useState } from "react";
import { Stack, Checkbox, TextField, Label } from "@fluentui/react";
import { IAnswer, IAvailableAnswer, IQuestion } from "../interfaces";

export interface IQuestionListProps {
    questions: IQuestion[];
    answersChanged: (answers: IAnswer[]) => void;
}

const QuestionList = ({ questions, answersChanged }: IQuestionListProps) => {
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const [questionsInternal, setQuestionsInternal] = useState<IQuestion[]>(questions);

    const updateAnswer = (questionId: string, value?: string) => {
        const answer = answers.find((a: IAnswer) => a.questionId === questionId);
        let newItems: IAnswer[] = [];
        if (answer) {
            const index = answers.indexOf(answer);
            newItems = [
                ...answers.slice(0, index),
                { ...answer, value },
                ...answers.slice(index + 1)
            ];
            setAnswers(newItems);
        } else {
            newItems = [...answers, { questionId, value }];
            setAnswers(newItems)
        }

        answersChanged(newItems);
    }

    const choiceChanged = (questionId: string, answerId: string, value?: boolean) => {
        const question = questionsInternal.find((q: IQuestion) => q.id === questionId && q.answerType === 3);
        const availableAnswer = question?.availableAnswers.find((a: IAvailableAnswer) => a.id === answerId);

        if (question && availableAnswer) {
            const aa = question.availableAnswers;
            const aaIndex = aa.indexOf(availableAnswer);
            const newAa = aa.map((a: IAvailableAnswer) => ({ ...a, checked: false }));
            const newAa2 = [
                ...newAa.slice(0, aaIndex),
                { ...availableAnswer, checked: value },
                ...newAa.slice(aaIndex + 1)
            ];

            const qIndex = questionsInternal.indexOf(question);
            setQuestionsInternal([
                ...questionsInternal.slice(0, qIndex),
                { ...question, availableAnswers: newAa2 },
                ...questionsInternal.slice(qIndex + 1)
            ]);

            updateAnswer(questionId, value ? availableAnswer?.valueInternal : "");
        }
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            {questionsInternal.map((x: IQuestion) =>
                <Stack.Item key={x.id}>
                    {/* Yes/No */}
                    {x.answerType === 1 &&
                        <Checkbox
                            label={x.value}
                            onChange={(_: any, checked?: boolean) => updateAnswer(x.id, checked ? "Ano" : "")}
                        />
                    }
                    {/* Text */}
                    {x.answerType === 2 &&
                        <TextField
                            label={x.value}
                            multiline
                            rows={3}
                            maxLength={1000}
                            onChange={(_: any, value?: string) => updateAnswer(x.id, value)}
                        />
                    }
                    {/* Multiple options */}
                    {x.answerType === 3 &&
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <Label>{x.value}</Label>
                            </Stack.Item>
                            {x.availableAnswers.map((a: IAvailableAnswer) =>
                                <Stack.Item>
                                    <Checkbox
                                        key={Math.random()}
                                        label={a.value}
                                        checked={a.checked}
                                        onChange={(_: any, checked?: boolean) => choiceChanged(x.id, a.id, checked)}
                                    />
                                </Stack.Item>
                            )}
                        </Stack>
                    }
                </Stack.Item>
            )}
        </Stack>
    )
}

export default QuestionList;
