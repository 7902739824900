import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {
  DefaultButton, PrimaryButton, Spinner, SpinnerSize, Dropdown, Stack, MessageBar, MessageBarType
} from "@fluentui/react";
import "./App.css";
import EventInfo from "./components/EventInfo";
import QuestionList from "./components/QuestionList";
import ParticipantList from "./components/ParticipantList";
import { getEvent, sendResponse } from "./services/dataService";
import { IAnswer, IData, IEvent, IQuestion } from "./interfaces";
import { convertToDate } from "./helpers/dateHelpers";

initializeIcons();

const searchParams = new URLSearchParams(window.location.search);
const participantId = searchParams.get("id") ?? "";

const App = () => {
  const { t } = useTranslation();
  document.title = t("documentTitle");

  const [loading, setLoading] = useState(true);
  const [showParticipants, setShowParticipants] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const [event, setEvent] = useState<IEvent>();
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [reaction, setReaction] = useState<number>(-1);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const missingArgs = !participantId;
  const tooLate = convertToDate(event?.latestResponseDate) <= new Date();

  useEffect(() => {
    if (!participantId) {
      setLoading(false);
    }

    const loadData = async () => {
      const response: IData = await getEvent(participantId);
      setEvent(response.event);
      setQuestions(response.questions);
      setLoading(false);
    };

    loadData();
  }, []);

  const sendData = async () => {
    await sendResponse({
      participantId,
      reaction,
      answers
    });
    setDataSent(true);
  }

  const answersChanged = (value: IAnswer[]) => {
    setAnswers(value);
  }

  const formIsValid = () => {
    return !dataSent && reaction !== -1;
  }

  const getReactions = (teamsEvent?: boolean) => {
    const reactions = [
      { key: 1, text: t("attendInPerson") },
    ];

    if (teamsEvent) {
      reactions.push({ key: 2, text: t("attendRemotely") });
    }

    reactions.push({ key: 3, text: t("iAmInterested") });
    reactions.push({ key: 4, text: t("wontAttend") });

    return reactions;
  }

  const renderLoader = <Spinner size={SpinnerSize.large} label={t("loading") ?? ""} />;
  const renderMissingArgs = (
    <MessageBar messageBarType={MessageBarType.warning}>
      Invalid URL address - missing parameter <em>id</em>
    </MessageBar>
  );
  const renderTooLate = <MessageBar messageBarType={MessageBarType.warning}>{t("tooLateMessage")}</MessageBar>;

  const renderForm = (
    <>
      <div className="header">
        <div className="headerTitle">{event?.name}</div>
      </div>
      <Stack tokens={{ childrenGap: 20 }}>
        {tooLate && renderTooLate}
        {!tooLate &&
          <Dropdown
            placeholder={t("selectReaction") ?? ""}
            options={getReactions(event?.teamsEvent)}
            required
            onChange={(_: any, option: any) => setReaction(option.key)}
          />
        }

        {reaction !== -1 && reaction !== 4 && <QuestionList questions={questions} answersChanged={answersChanged} />}
        {dataSent && <MessageBar>{t("dataSent")}</MessageBar>}

        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
          <Stack.Item>
            <DefaultButton onClick={() => setShowParticipants(true)}>
              {t("showParticipantsButton")}
            </DefaultButton>
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton disabled={!formIsValid() || tooLate} onClick={sendData}>
              {t("submitButton")}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
        
        <EventInfo {...event} />
      </Stack>

      {showParticipants &&
        <ParticipantList participants={event?.participants ?? []} onClose={() => setShowParticipants(false)} />
      }
    </>
  );

  return (
    <div className="mainContainer">
      <div className="container">
        {loading && renderLoader}
        {!loading && !missingArgs && renderForm}
        {!loading && missingArgs && renderMissingArgs}
      </div>
    </div>
  );
}

export default App;
